import React from "react";
import { domToReact } from "html-react-parser";

const Spacer = (props) => {
  const {
    domNode,
    options
  } = props;

  let style = (domNode?.attribs?.style || "");
  if (style != "") {
    style = style.split(";").filter(e => e.length); 
    style.push("margin-top: "+domNode.prev.data.split("gco-spacer:")[1]);
    domNode.attribs.style = style.join(";");
  }
  domNode.prev = null;
  return (<>{ domToReact([domNode], options) }</>);
};

export default Spacer;
